/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ol: "ol",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Each of the Parties:"), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "will allow the other reasonable opportunity to comply before it claims that the other has not met its obligations under this Agreement,"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "will attempt in good faith to resolve all disputes, disagreements, or claims between the parties relating to this Agreement,"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "hereby submits to the exclusive jurisdiction of the Norwegian courts in Oslo City Court (Oslo byrett),"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "agrees to the application of the laws of Norway, excluding Norway’s choice-of-law principles, to govern, interpret, and enforce all of your and our respective rights, duties, and obligations relating to or arising out of this agreement, or the breach thereof, whether sounding in contract, tort or otherwise, and"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, "is responsible for complying with laws and regulations applicable to its business and Content."), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
